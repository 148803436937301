import { createStore } from 'vuex'
import { authApi } from '@/api';
import { tokenIsExp } from '@/utils/utils'

export default createStore({
  state: {
    token: localStorage.getItem("token") || "",
    merchant: null,
    spotId: null,
    modules: [],
    serviceLanguages: [],
    loadingShow: false,
    AzureTranslatorToken: localStorage.getItem("AzureTranslatorToken") || "",
    AzureSpeechToken: localStorage.getItem("AzureSpeechToken") || "",
    scrollPosition: [],
  },
  getters: {
    token: state => state.token,
    merchant: state => state.merchant,
    spotId: state => state.spotId,
    isLogin: state => !!state.token,
    modules: state => state.modules,
    serviceLanguages: state => state.serviceLanguages,
    loadingShow: state => state.loadingShow,
    isFavorite: state => state.merchant?.base?.isFavorite || false,
    scrollPosition: state => state.scrollPosition,
  },
  mutations: {
    setToken (state, token) {
      state.token = token;
    },
    setMerchant (state, data) {
      state.merchant = data;
    },
    setSpotId (state, data) {
      state.spotId = data;
    },
    clearMerchant (state) {
      state.merchant = null;
    },
    setModules (state, data) {
      state.modules = data;
    },
    setServiceLanguages (state, data) {
      state.serviceLanguages = data;
    },
    setLoadingShow (state, data) {
      state.loadingShow = data;
    },
    setMerchantFavorite (state, data) {
      const base = state.merchant?.base;
      if (base) {
        state.merchant.base.isFavorite = data;
      }
    },
    checkAzureSpeechToken (state, data) {
      state.AzureSpeechToken = data;
    },
    checkAzureTranslatorToken (state, data) {
      state.AzureTranslatorToken = data;
    },
    setScrollPosition (state, data) {
      // data 为 name 和 position
      const { name, position } = data;
      // 如果没有 name 或者 position 则不做处理
      if (!name || !position) {
        return;
      }
      // 如果有，查找数组中是否有相同的 name 的数据，有则替换，没有则添加
      const index = state.scrollPosition.findIndex(item => item.name === name);
      if (index > -1) {
        state.scrollPosition[index] = data;
      } else {
        state.scrollPosition.push(data);
      }
    },
  },
  actions: {
    setToken ({ commit }, token) {
      commit("setToken", token);
    },
    setMerchant ({ commit }, data) {
      commit("setMerchant", data);
    },
    setSpotId ({ commit }, data) {
      commit("setSpotId", data);
    },
    clearMerchant ({ commit }) {
      commit("clearMerchant");
    },
    setModules ({ commit }, data) {
      commit("setModules", data);
    },
    setServiceLanguages ({ commit }, data) {
      commit("setServiceLanguages", data);
    },
    setLoadingShow ({ commit }, data) {
      commit("setLoadingShow", data);
    },
    resetData ({ commit }) {
      commit("setMerchant", null);
      commit("setModules", []);
      commit("setServiceLanguages", []);
    },
    setMerchantFavorite ({ commit }, data) {
      commit("setMerchantFavorite", data);
    },
    checkAzureTranslatorToken ({ commit, state }) {
      const tokenPromise = new Promise(async (resolve, reject) => {
        const isExp = tokenIsExp(state.AzureTranslatorToken)
        if (isExp) {
          const res = await authApi.getPublicUserAzureTranslator().catch(() => {
            return {
              data: {
                token: ""
              }
            }
          })
          state.AzureTranslatorToken = res.data.token
          localStorage.setItem("AzureTranslatorToken", res.data.token)
        }
        if (state.AzureTranslatorToken) {
          commit("checkAzureTranslatorToken", state.AzureTranslatorToken)
          resolve(state.AzureTranslatorToken)
        } else {
          reject()
        }
      })
      return tokenPromise
    },
    checkAzureSpeechToken ({ commit, state }) {
      const tokenPromise = new Promise(async (resolve, reject) => {
        const isExp = tokenIsExp(state.AzureSpeechToken)
        if (isExp) {
          const res = await authApi.getPublicUserAzureSpeech().catch(() => {
            return {
              data: {
                token: ""
              }
            }
          })
          state.AzureSpeechToken = res.data.token
          localStorage.setItem("AzureSpeechToken", res.data.token)
        }
        if (state.AzureSpeechToken) {
          commit("checkAzureSpeechToken", state.AzureSpeechToken)
          resolve(state.AzureSpeechToken)
        } else {
          reject()
        }
      })
      return tokenPromise
    },
    setScrollPosition ({ commit }, data) {
      commit("setScrollPosition", data);
    },
  },
})
