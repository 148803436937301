<template>
  <div class="loading-body">
    <van-loading type="spinner" />
  </div>
</template>
<script>
export default {}
</script>
<style scoped>
.loading-body {
  height: 50rem;
  text-align: center;
}
</style>
