<template>
  <img :src="srcCom" :alt="alt" />
</template>
<script>
import { computed } from 'vue'
import store from '@/store'

export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    srcType: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const srcCom = computed(() => {
      const pathAssets = process.env.VUE_APP_PATH_ASSETS
      const domain = store.getters.merchant?.base?.domain || '/'
      switch (props.srcType) {
        case '0':
          // 如 https://spot.jp.shequchina.cn/ + /uploads/20230825/b30c621b329a2f1da6267cb37659114d.jpg
          return `${domain}${props.src}`
        case '1':
          // 如 https://spot.jp.shequchina.cn/ + qrhotel/dev + /uploads/20230825/b30c621b329a2f1da6267cb37659114d.jpg
          return `${domain}${pathAssets}${props.src}`
        case '2':
          // 如 https://spot.jp.shequchina.cn/ + spot/thumb/ + uploads/20230825/b30c621b329a2f1da6267cb37659114d.jpg
          return `${domain}spot/thumb/${props.src}`
        case '3':
          // 如 https://spot.jp.shequchina.cn/ + food/thumbnail/ + b30c621b329a2f1da6267cb37659114d.jpg
          return `${domain}food/thumbnail/${props.src}`
        case '4':
          // 如 https://spot.jp.shequchina.cn/ + food/ + b30c621b329a2f1da6267cb37659114d.jpg
          return `${domain}food/${props.src}`
        default:
          return `https://default.com/${props.src}`
      }
    })
    return {
      srcCom,
    }
  },
}
</script>
<style scoped></style>
